// Copyright (C) 2017-2022 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.options-menu-container {
    width: 15rem;

    .option-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 4rem;

        .icon {
            flex: none;
            width: 1.4rem;
            height: 1.4rem;
            margin: 1.3rem;
            fill: @color-surface-light5-90;
        }

        .label {
            flex: 1;
            max-height: 2.4em;
            font-weight: 400;
            color: @color-surface-light5-90;
        }

        &:hover {
            background-color: @color-background-light2;
        }

        &:global(.disabled) {
            opacity: 0.5;
        }
    }
}